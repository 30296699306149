import React, { useEffect, useRef } from "react";
import { useInViewport } from "react-in-viewport";

import { filter } from "lodash-es";
import Text from "ab-text";
import Gallery from "ab-gallery";
import Link from "ab-link";
import ParagraphText from "ab-paragraph-text";

import "./carrousel-full.scss";

const CarrouselFull = ({
  title = "Una experiencia gastronómica",
  text = [],
  content,
  images,
  imgmob,
  className = "",
  classname = "",
  link = [],
  links = [],
  href,
  animated,
  linksAfter = false,
  linksafter = false,
  singleColumn,
  singlecolumn,
  video,
  videoembed,
  element = "h2",
  textClass,
  textclass,
  h1,
  navlink,
}) => {
  // console.log("links", links);
  // console.log("link", link);

  const myRef = useRef();

  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef,
    { threshold: 0.5 },
    {
      onEnterViewport: () => {
        // console.log("entered viewport");
      },
    },
    { disconnectOnLeave: false }
  );
  const handleScroll = () => {
    const element = document.getElementById(`menu-#${navlink}`);
    const menu = document.getElementById("history-menu");

    if (element) {
      if (inViewport) {
        if (!element?.classList?.contains("selected")) {
          const children = menu?.querySelectorAll("li");
          children.forEach(function(e) {
            if (e.id !== element.id) {
              e?.classList.remove("selected");
              e?.classList.add("not-selected");
            }
          });
          element?.classList.add("selected");
          element?.classList.remove("not-selected");
          menu.scrollLeft = element?.offsetLeft - 10;
        }
      } else {
        if (!element?.classList?.contains("not-selected")) {
          element?.classList.remove("selected");
          element?.classList.add("not-selected");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inViewport]);

  const renderInner = () => {
    return (
      <>
        <Text
          element={element}
          animated={animated}
          className={textClass || textclass}
        >
          {title}
        </Text>

        <ParagraphText
          text={text}
          content={content}
          singleColumn={singleColumn || singlecolumn}
        />
      </>
    );
  };

  return (
    <>
      {navlink ? (
        <a className="reference" style={{ top: -90 }} id={navlink} />
      ) : null}
      <div
        className={`carrousel-full ${className || classname || ""}`}
        ref={myRef}
      >
        {h1 ? <Text element="h1">{h1}</Text> : null}
        {href ? (
          <a
            className={`row${
              (link.length > 0 || links?.length > 0) &&
              (!linksAfter || !linksafter)
                ? " no-margin"
                : ""
            }`}
            href={href}
          >
            {renderInner()}
          </a>
        ) : (
          <div
            className={`row${
              (link.length > 0 || links?.length > 0) &&
              !linksAfter &&
              !linksafter
                ? " no-margin"
                : ""
            }`}
          >
            {renderInner()}
          </div>
        )}
        {link.length > 0 ? (
          <div className={`links${linksAfter && linksafter ? " after" : ""}`}>
            {link.map((itm) => (
              <Link
                prefetch={false}
                text={itm.text}
                href={itm.href}
                target={itm.target}
              />
            ))}
          </div>
        ) : null}
        {filter(links, (itm) => itm?.link)?.length > 0 ? (
          <div className={`links${linksAfter || linksafter ? " after" : ""}`}>
            {links.map((itm, index) => (
              <Link
                prefetch={false}
                text={itm.link?.title}
                href={itm.link?.url}
                target={itm.link?.target}
                key={`link-${index}`}
              />
            ))}
          </div>
        ) : null}
        {images?.length > 0 ? (
          <Gallery
            images={images}
            imagesMobile={imgmob}
            hideCounter={(className || classname)?.includes("hide-counter")}
          />
        ) : null}
        {video}
        {videoembed ? (
          <iframe
            width="1216"
            height="600"
            src={`${videoembed.replace(
              "https://www.youtube.com/watch?v=",
              "https://www.youtube-nocookie.com/embed/"
            )}?modestbranding=1&controls=0&showinfo=0&mode=opaque&autohide=1&wmode=transparent`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : null}

        {
          <ParagraphText
            text={text}
            className={"duplicate"}
            singleColumn={singleColumn || singlecolumn}
          />
        }
      </div>
    </>
  );
};

export default CarrouselFull;
